export default [
  {
    path: "",
    text: "home",
  },
  {
    path: "posts",
    text: "archive",
  },
  {
    path: "categories",
    text: "categories",
  },
  {
    path: "about",
    text: "about",
  },
  // {
  //   path: "index.xml",
  //   text: "rss",
  // },
]
